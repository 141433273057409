import { Link } from "react-router-dom";
import { getIconSrc } from "@app/components/Button/helpers";
import { IconName } from "@app/components/Button/types";
import btWhatsapp from "@assets/budget/qr-code-whatsapp.svg";
import defaultMastheadImage from "@assets/logo-masthead.svg";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";

export default function Whatsapp(): React.ReactElement {
  const sendDataToGTM = useGTMDispatch();

  const handleOnClickLink = () => {
    sendDataToGTM({
      event: "custom_event",
      eventCategory: "budget 2025",
      eventAction: "click",
      eventLabel: "whatsapp",
    });
  };

  return (
    <div className="border border-gray-850 p-2">
      <div className="relative bg-gray-250 px-3 py-6">
        <div className="relative block w-full flex-none pt-5 lg:hidden">
          <div className="flex justify-center">
            <img
              src={defaultMastheadImage}
              alt="The Business Times"
              width={170}
              height={15}
            />
          </div>
        </div>

        <img
          src={getIconSrc(IconName.whatsapp)}
          width={72}
          height={72}
          alt="whatsapp icon"
          className="mx-auto hidden lg:block"
        />

        <h3 className="mt-4 hidden text-center font-lct text-6xl text-gray-850 lg:block ">
          Follow BT on
        </h3>

        <h3 className="hidden text-center font-lct text-6xl text-gray-850 lg:block">
          Whatsapp
        </h3>

        <p className="m-0 px-9 py-11 text-center font-lct text-8xl lg:px-2 lg:py-0 lg:font-poppins lg:text-xs lg:font-light">
          Breaking news and top stories, delivered right to your phone.
        </p>

        <img
          src={btWhatsapp}
          width={136}
          height={136}
          alt="BT Whatsapp"
          className="mx-auto mt-6 hidden lg:block"
        />

        <p className="mt-3 hidden text-center text-7xs uppercase lg:block">
          Scan the QR Code
        </p>

        <div className="mx-auto flex w-max items-center rounded-[4px] border border-gray-850 bg-white px-10 py-3 text-center lg:hidden">
          <img
            src={getIconSrc(IconName.whatsapp)}
            width={30}
            height={30}
            alt="whatsapp icon"
            className="mr-3"
          />

          <p className="font-poppins text-xs font-bold">BT on WhatsApp</p>
        </div>

        <Link
          onClick={handleOnClickLink}
          to={"https://www.whatsapp.com/channel/0029VaI0vLF29753DedKkb24"}
          target="_blank"
          className="absolute inset-0"
        />
      </div>
    </div>
  );
}
