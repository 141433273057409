/**
 * Company: SPHMedia
 * Description: SME Vertical Layout
 */

import { ReactElement } from "react";
import { RouteFactory } from "@app/routePaths";
import { VerticalProps } from "@app/types/Verticals";
import { getSectionJSONLD } from "@app/util/helpers";
import Ad from "@components/Advertisement/Ad";
import AdSettings from "@components/Advertisement/AdSettings";
import Container from "@components/Container/Container";
import Header from "@components/Header/Header";
import MetaTags from "@components/MetaTags/MetaTags";
import {
  BT_NEWSLETTERS,
  NEWSLETTER_TYPE,
} from "@components/Newsletter/types/Newsletter";
import NewsletterSignUpPanel from "@components/Newsletter/Verticals/NewsletterSignUp";
import PulseEntry from "@pages/Pulse/components/PulseEntry/PulseEntry";
import KeywordStoriesRow from "@pages/Section/components/VerticalMeta/KeywordStoriesRow";
import LatestStoriesRow from "@pages/Section/components/VerticalMeta/LatestStoriesRow";
import TopStoriesRow from "@pages/Section/components/VerticalMeta/TopStoriesRow";

import SMEBanner from "./components/SMEBanner";
import SMENewsletterDescription from "./components/SMENewsletterDescription";

export default function SMEVertical({
  data: { entity, topStories, additional, title },

  mostReadArticles,
}: VerticalProps): ReactElement {
  return (
    <div className="w-full">
      <AdSettings
        adNames={["lb1", "imu2", "prestitial", "catfish", "abm"]}
        path={RouteFactory.globalEnterprise}
        adTargets={[{ key: "page", value: "listing" }]}
      />

      <MetaTags
        title={title}
        description="THE BUSINESS TIMES SGSME - Find SGSME News & Headlines, insight and analysis in Singapore, Asia-Pacific & global markets news at The Business Times."
        ogType="article"
        slug={RouteFactory.sgsme}
        jsonLd={getSectionJSONLD(entity)}
      />

      <Ad
        adType="lb1"
        className="flex min-h-[calc(50px+0.50rem)] flex-col items-center justify-center border-b border-gray-175 bg-gray-125 py-1 text-center md:py-3 lg:min-h-[calc(250px+1.5rem)]"
      />

      <Header />

      <PulseEntry />

      <Container>
        <SMEBanner />

        <TopStoriesRow articles={topStories} verticalName="sme" />

        <LatestStoriesRow
          articles={topStories.slice(4, 9)}
          seeMoreLink={`${RouteFactory.sgsme}/latest`}
          verticalName="sme"
          mostReadStories={mostReadArticles}
        />

        <KeywordStoriesRow
          seeMoreLink={RouteFactory.keywords("singapore-smes")}
          data={additional?.[0]}
          verticalName="sme"
          verticalBlockTitle="SINGAPORE SMES"
        />
      </Container>

      <NewsletterSignUpPanel
        rootClassName="mb-3"
        descriptionComponent={<SMENewsletterDescription />}
        newsletter={BT_NEWSLETTERS.filter(
          (newsletter) => newsletter.type === NEWSLETTER_TYPE.SGSME
        )}
        btnColors="bg-verticals-sme text-white hover:bg-white hover:text-verticals-sme"
        linkColors="text-verticals-sme"
      />

      <Ad adType="catfish" />

      <Ad adType="abm" />

      <Ad adType="prestitial" />
    </div>
  );
}
