import { Link } from "react-router-dom";
import logoSBA from "@assets/events-awards/logo-sba.svg";
import Ad from "@components/Advertisement/Ad";
import AdSettings from "@components/Advertisement/AdSettings";
import Container from "@components/Container/Container";
import GAData from "@components/GAData/GAData";
import Column from "@components/Grid/Column";
import Row from "@components/Grid/Row";
import Header from "@components/Header/Header";
import { useBreadCrumbState } from "@components/Header/helpers";
import MetaTags from "@components/MetaTags/MetaTags";
import {
  EVENTS_AWARDS_SBA_LABEL,
  EVENTS_AWARDS_SBA_PATH,
  EVENTS_AWARDS_SLOT,
} from "@pages/Section/layouts/EventsAwardsPage/utils/constants";
import { EventsAwardsLandingPageContextData } from "@pages/Section/layouts/EventsAwardsPage/utils/types";
import { sectionNavigationItems } from "@util/constant";
import { useDetectAdBlock } from "adblock-detect-react";

export default function EventsAwardsSBAPageContent({
  title,
  // article,
}: EventsAwardsLandingPageContextData): React.ReactElement {
  const { isShowBreadCrumb } = useBreadCrumbState();
  const adBlockDetected = useDetectAdBlock();

  return (
    <div
      className="w-full"
      data-testid="events-awards-sba-page-content-component"
    >
      <AdSettings
        adNames={["lb1", "prestitial", "catfish", "abm"]}
        path={EVENTS_AWARDS_SBA_PATH}
        adTargets={[{ key: "page", value: "listing" }]}
      />

      <GAData
        title="Singapore_Business_Awards_Index"
        level2="events awards"
        adblocker={adBlockDetected ? 1 : 0}
      />

      <MetaTags
        title={title}
        description={`THE BUSINESS TIMES ${EVENTS_AWARDS_SBA_LABEL} - Find ${EVENTS_AWARDS_SBA_LABEL} News & Headlines, insight and analysis in Singapore, Asia-Pacific & global markets news at The Business Times.`}
        ogType="website"
        slug={EVENTS_AWARDS_SBA_PATH}
      />

      <Ad
        adType="lb1"
        className="flex min-h-[calc(50px+0.50rem)] flex-col items-center justify-center border-b border-gray-175 bg-gray-125 py-1 text-center md:py-3 lg:min-h-[calc(250px+1.5rem)]"
      />

      <Header
        displayBreadcrumb={isShowBreadCrumb}
        parentCategory={EVENTS_AWARDS_SLOT}
        categoryDataMapping={sectionNavigationItems}
        enableTopStoriesLink={false}
      />

      <Container>
        <Row rootClassName="justify-center">
          <Column rootClassName="w-full lg:w-8/12 text-center my-8">
            <Link to={EVENTS_AWARDS_SBA_PATH}>
              <img
                src={logoSBA}
                alt="Singapore Business Awards logo"
                width={500}
                height={71}
                className="mx-auto"
              />
            </Link>
          </Column>
        </Row>
      </Container>

      <Ad adType="catfish" />

      <Ad adType="abm" />

      <Ad adType="prestitial" />
    </div>
  );
}
