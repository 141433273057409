import { handleAlacrity } from "@api/endpoint-handlers/bff-functions/Alacrity/Alacrity";
import {
  ArticleDataObject,
  CookGQL,
  RelatedStory,
  SanitizedRelatedStory,
  SanitizedSectionArticle,
  seeAlso,
} from "@app/types/Cue";
import { ArticleContext } from "@app/types/Page";
import { excludedKeywords } from "@caas/queries/constant";
import { genSanitizedRelatedStoriesQuery } from "@caas/queries/genSanitizedRelatedStoriesQuery";
import { genSectionQuery } from "@caas/queries/genSectionQuery";
import { genSeeAlsoQuery } from "@caas/queries/genSeeAlsoQuery";
import { queryByKeywords } from "@caas/queries/queryByKeywords";
import { queryCaasOpenSearch } from "@caas/queryCaasOpenSearch";
import {
  LAMBDA_REQUEST_ACTION_TYPES,
  LAMBDA_RESPONSE_STATUS,
  LambdaResponsePayload,
} from "@pages/Article/types/Alacrity";
import {
  getKeywordsDependentPaths,
  getKeywordsToDisplayItems,
  getPathsFromAuthors,
  getPathsFromStoryThreads,
  getSectionDependentPaths,
  getSupplementsDependenthPaths,
} from "@pages/Article/utils/helpers";
import { defaultSourceForListing } from "@pages/Section/constants";
import { ResponseType, TRouteWithRedirect } from "@sphtech/web2-core/ssr";
import { ENVIRONMENT } from "@util/constant";
import { getEnvironmentDomain } from "@util/helpers";
import { santizeArticleDataObjects } from "@util/sanitization/sanitizeArticleDataObjects";
import { HttpStatusCode } from "axios";

// @TODO: Refactor this function because it is too messy.
// Separate the request calls for data and re-use it.
// Similar code can be seen in getNextArticle function.
export const processArticleData = async (
  article: ArticleDataObject,
  isPreview?: boolean,
  sort?: number[]
): Promise<TRouteWithRedirect<ArticleContext, string>> => {
  if (article && article.relatedStories && article.relatedStories.length > 0) {
    const sanitizedRelatedStories = await fetchSanitizedRelatedStories(
      article.relatedStories
    );

    if (sanitizedRelatedStories) {
      article = {
        ...article,
        sanitizedRelatedStories: sanitizedRelatedStories,
      };
    }
  }
  const urlPath = article.urlPath;

  if (typeof urlPath === "undefined") {
    return {
      type: ResponseType.SERVER_ERROR,
      statusCode: HttpStatusCode.InternalServerError,
      payload: "Article URL path is undefined",
    };
  }

  const longURL = `${getEnvironmentDomain(ENVIRONMENT)}${urlPath}`;
  const shortUrl = await fetchShortUrl(longURL);

  const artContext = {
    ...article,
    sort: sort,
    shortUrl: shortUrl || longURL,
  };

  let sectionContext: SanitizedSectionArticle[] = [];
  let seeAlsoContext: SanitizedSectionArticle = {} as SanitizedSectionArticle;
  const uniqueName = artContext?.sections?.[0]?.uniqueName;
  const keyword = artContext?.tags?.[0]?.urlPath;
  const externalURL = artContext?.displaySetting?.externalURL;
  const excludedId = artContext.id;

  if (typeof uniqueName === "undefined") {
    return {
      type: ResponseType.CLIENT_ERROR,
      statusCode: HttpStatusCode.NotFound,
      payload: "Article doesn't have main section.",
    };
  }

  if (externalURL && longURL !== externalURL) {
    // Pass redirect response if externalURL is present.
    return {
      type: ResponseType.REDIRECT,
      statusCode: 301,
      target: externalURL,
    };
  }

  const keywordsToUse = getKeywordsToDisplayItems(artContext.tags || []);

  if (keywordsToUse) {
    const query = queryByKeywords(
      keywordsToUse.urlPath,
      9, // page size
      0, // page
      defaultSourceForListing
    );
    const response = await queryCaasOpenSearch(query);
    const stories = response.payload?.hits.hits?.map((article) => {
      const context = article._source.data.context;

      return { ...context, sort: article.sort };
    });

    sectionContext = santizeArticleDataObjects(stories);
  }

  if (uniqueName && !keywordsToUse) {
    const exclusions =
      uniqueName === "thrive"
        ? excludedKeywords.filter(
            (keyword) => keyword !== "/keywords/thrive-newsletter"
          )
        : undefined;
    const sectionQuery = genSectionQuery(uniqueName, 9, exclusions, [
      excludedId,
    ]);
    const sectionResponse = await queryCaasOpenSearch(sectionQuery);

    const sectionStories = sectionResponse.payload?.hits.hits?.map(
      (article) => {
        const context = article._source.data.context;

        return { ...context, sort: article.sort };
      }
    );
    sectionContext = santizeArticleDataObjects(sectionStories);
  }

  if (keyword) {
    const seeAlsoQuery = genSeeAlsoQuery(keyword, excludedId);
    const seeAlsoResponse = await queryCaasOpenSearch(seeAlsoQuery);

    const seeAlsoStories = seeAlsoResponse.payload?.hits.hits?.map(
      (article) => {
        const context = article._source.data.context;

        return { ...context, sort: article.sort };
      }
    );

    seeAlsoContext = santizeArticleDataObjects(seeAlsoStories)?.[0];
  }

  const seeAlso = {
    articleId: seeAlsoContext?.id,
    title: seeAlsoContext?.title,
    slug: seeAlsoContext?.urlPath,
  } as seeAlso;

  const dependentPaths = [
    "",
    "/",
    "/breaking-news",
    ...getSectionDependentPaths(artContext?.sections),
    ...getKeywordsDependentPaths(artContext?.tags || []),
    ...getPathsFromStoryThreads(artContext?.others),
    ...getPathsFromAuthors(artContext?.authors),
    ...getSupplementsDependenthPaths(artContext?.tags || []),
  ];

  return {
    type: ResponseType.SUCCESS,
    statusCode: 200,
    dependentPaths: dependentPaths,
    payload: {
      kind: "art",
      data: {
        cook: {
          data: {
            context: artContext,
          },
        } as CookGQL,
        sectionNews: sectionContext,
        seeAlso: seeAlso,
        isPreview: isPreview,
      },
    },
  };
};

export const fetchShortUrl = async (urlPath: string) => {
  const payload = {
    action: LAMBDA_REQUEST_ACTION_TYPES.GENERATE_SHORTEN_URL,
    data: {
      articleURL: urlPath,
    },
  };

  try {
    const response: LambdaResponsePayload = await handleAlacrity(payload);

    // Guard clauses
    if (response.status !== LAMBDA_RESPONSE_STATUS.SUCCESS) return;
    return response?.data?.shortURL;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(
      `Error occurred while fetching short URL for urlPath: ${urlPath}. Details: ${(err as Error).message}`
    ); // eslint-disable-line no-console
    return;
  }
};

/**
 * Fetches sanitized related stories based on provided related stories.
 *
 * @param relatedStories Array of related stories.
 * @returns Array of sanitized related stories.
 */
export const fetchSanitizedRelatedStories = async (
  relatedStories: RelatedStory[]
) => {
  // The current title value from the payload of CUE contains relatedStories.field.title as a slug version of the title
  // and not the actual title, hence another OS query is made to fetch the necessary values.
  // TODO: Remove this part once CUE payload is modified and re-indexing is done on CaaS.

  const related_stories_ids = relatedStories.map((story) => story.content.id);
  const query = genSanitizedRelatedStoriesQuery(related_stories_ids);
  const response = await queryCaasOpenSearch(query);

  const sanitizedRelatedStories: SanitizedRelatedStory[] = [];

  const hits = response.payload?.hits.hits;

  for (const hit of hits) {
    const { id, title, media, sections } = hit._source.data.context;
    const urlPath =
      hit._source.data.context.displaySetting?.externalURL ||
      hit._source.data.context.urlPath;

    sanitizedRelatedStories.push({
      id,
      title,
      urlPath,
      media,
      sections,
    });
  }

  return sanitizedRelatedStories;
};
