import geLogo from "@assets/general-election/ge-logo-v1.svg";
import Ad from "@components/Advertisement/Ad";
import AdSettings from "@components/Advertisement/AdSettings";
import Container from "@components/Container/Container";
import GAData from "@components/GAData/GAData";
import Header from "@components/Header/Header";
import KeywordFilter from "@components/KeywordFilter";
import MetaTags from "@components/MetaTags/MetaTags";
import PulseEntry from "@pages/Pulse/components/PulseEntry/PulseEntry";
import { getFormattedTextForAds } from "@pages/Section/helpers";
import { TagType } from "@pages/Section/types";
import {
  defaultConfig,
  GE2025_PAGE_QUERY,
} from "@pages/SingaporeGe/2025/utils/constants";
import { Ge2025Data } from "@pages/SingaporeGe/utils/types";
import SectionsTemplate from "@src/app/pages/SingaporeGe/2025/components/SectionsTemplate";
import { useDetectAdBlock } from "adblock-detect-react";

type SingaporeGe2025Props = {
  title: string;
  data: Ge2025Data;
  description: string;
};

export default function SingaporeGe2025Page({
  title,
  data,
  description,
}: SingaporeGe2025Props): React.ReactElement {
  const adBlockDetected = useDetectAdBlock();
  const { highlights, relatedStories, config, comingUp } = data;

  const configData = config?.contentListFields?.configObj || defaultConfig;

  const parentCategory = GE2025_PAGE_QUERY.substring(1);

  return (
    <div className="w-full" data-testid="singapore-ge-2025-page-component">
      <AdSettings
        adNames={["lb1", "imu2", "catfish", "abm", "prestitial"]}
        path={GE2025_PAGE_QUERY}
        adTargets={[
          { key: "page", value: "listing" },
          {
            key: "bttags",
            value: getFormattedTextForAds("Singapore GE 2025"),
          },
        ]}
      />

      <MetaTags
        title={title}
        description={description}
        ogType="website"
        slug={GE2025_PAGE_QUERY}
      />

      <GAData
        title="Singapore_Ge_2025"
        level2="miscellaneous"
        adblocker={adBlockDetected ? 1 : 0}
      />

      <Ad
        adType="lb1"
        className="flex min-h-[calc(50px+0.50rem)] flex-col items-center justify-center border-b border-gray-175 bg-gray-125 py-1 text-center md:py-3 lg:min-h-[calc(250px+1.5rem)]"
      />

      <Header parentCategory={parentCategory} />

      <PulseEntry />

      <Container rootClassName="pt-6">
        <div className="mb-3 flex items-center justify-between border-b border-gray-850 pb-3">
          <img
            src={geLogo}
            width={150}
            height={60}
            alt="Singapore GE 2025"
            className="w-[130px] lg:w-[150px]"
          />

          <KeywordFilter
            followtextButton
            tag={{
              name: "Singapore GE 2025",
              uri: GE2025_PAGE_QUERY,
              aliases: [],
              fields: [],
              id: "singapore-ge-2025",
              parent: null,
              type: TagType.Keyword,
              urlPath: GE2025_PAGE_QUERY,
              urlPathHistory: [],
              sections: [],
            }}
            disableTooltip
            rootClassName="inline-block"
            tagClassName="hidden"
          />
        </div>

        <>
          {configData.map((config, index) => {
            return (
              <SectionsTemplate
                key={`${config.type}-${index}`}
                configData={config}
                highlights={highlights}
                relatedStories={relatedStories}
                comingUp={comingUp}
                index={index}
              />
            );
          })}
        </>
      </Container>

      <Ad adType="catfish" />

      <Ad adType="abm" />

      <Ad adType="prestitial" />
    </div>
  );
}
