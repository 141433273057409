import { RouteFactory } from "@app/routePaths";
import type { NavigationItem } from "@app/types/Navigation";
import epaperAltIcon from "@assets/footer/icon-epaper-alt.svg";
import logoAB from "@assets/footer/logo-asean-business.svg";
import logoBTLifeStyle from "@assets/footer/logo-bt-lifestyle.svg";
import logoBTLuxe from "@assets/footer/logo-bt-luxe.svg";
import logoBTThrive from "@assets/footer/logo-bt-thrive.svg";
import logoGE from "@assets/footer/logo-global-enterprise.svg";
import logoSGSME from "@assets/footer/logo-sg-sme.svg";
import logoWM from "@assets/footer/logo-wealth-magazine.svg";
import defaultIconAppGoogle from "@assets/icon-app-google.svg";
import defaultIconAppIOS from "@assets/icon-app-ios.svg";
import { IconName } from "@components/Button/types";
import type {
  CopyrightItem,
  LogoItem,
  SocialLinkConfig,
} from "@components/Footer/types";
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faWhatsapp,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { faNewspaper } from "@fortawesome/free-regular-svg-icons";
import {
  faListDots,
  faPaperPlane,
  faRss,
} from "@fortawesome/free-solid-svg-icons";
import {
  EPAPER_API_ENDPOINT,
  ESHOP_SUBSCRIBE_CTA_FOOTER,
  ESHOP_SUBSCRIBE_CTA_HEADER,
  sectionNavigationItems,
} from "@util/constant";
import dayjs from "dayjs";

export const IOS_APP_LINK =
  "https://apps.apple.com/sg/app/the-business-times/id531275824";
export const ANDROID_APP_LINK =
  "https://play.google.com/store/apps/details?id=com.ps.bt&hl=en";

const appDownloadLinks = {
  ios: {
    src: defaultIconAppIOS,
    link: IOS_APP_LINK,
    alt: "Download on the Apple Store",
  },
  google: {
    src: defaultIconAppGoogle,
    link: ANDROID_APP_LINK,
    alt: "Download on the Google Play Store",
  },
};

const socialLinks: Record<string, SocialLinkConfig> = {
  fb: {
    icon: faFacebookF,
    link: "https://www.facebook.com/thebusinesstimes",
    alt: "Share on Facebook",
  },
  fbBlack: {
    icon: faFacebookF,
    link: "https://www.facebook.com/thebusinesstimes",
    alt: "Share on Facebook",
  },
  twitter: {
    icon: faXTwitter,
    link: "https://twitter.com/businesstimes",
    alt: "Share on Twitter",
  },
  linkedin: {
    icon: faLinkedinIn,
    link: "https://www.linkedin.com/showcase/the-business-times",
    alt: "Share on Linkedin",
  },
  telegram: {
    icon: faPaperPlane,
    link: "https://t.me/BizTimes",
    alt: "Share on Telegram",
  },
  rss: {
    icon: faRss,
    link: "/rss-feeds",
    alt: "Subscribe to RSS",
  },
  ig: {
    icon: faInstagram,
    link: "https://www.instagram.com/businesstimessg",
    alt: "Share on Instagram",
  },
  epaper: {
    icon: faNewspaper,
    link: `${EPAPER_API_ENDPOINT}/`,
    alt: "Subscribe to Epaper",
    label: "E-PAPER",
  },
  epaperBTArchive: {
    icon: faNewspaper,
    link: `${EPAPER_API_ENDPOINT}/jr/?archive=open&tour=close`,
    alt: "Subscribe to Epaper",
    label: "BT 12-DAY PRINT ARCHIVE",
  },
  whatsapp: {
    icon: faWhatsapp,
    link: "https://wa.me/?text=THE%20BUSINESS%20TIMES%20-%20Get%20the%20Latest%20Business%20%26%20Financial%20News%20%0D%0Ahttps%3A%2F%2Fwww.businesstimes.com.sg",
  },
  more: { icon: faListDots, link: "" },
};

const navigationItems: Record<
  string,
  Pick<NavigationItem, "link" | "label">
> = {
  "about-us": {
    label: "about us",
    link: RouteFactory.aboutUs,
  },
  "contact-us": {
    label: "contact us",
    link: RouteFactory.contactUs,
  },
  advertise: {
    label: "advertise with us",
    link: `${RouteFactory.advertise}?ref=footer`,
  },
  pr: {
    label: "Paid Press Releases",
    link: RouteFactory.paidPressRelease,
  },
  help: {
    label: "help",
    link: RouteFactory.help,
  },
  "privacy-policy": {
    label: "privacy policy",
    link: "https://www.sph.com.sg/legal/sph_privacy/",
  },
  "terms-conditions": {
    label: "terms & conditions",
    link: "https://www.sph.com.sg/legal/website_tnc/",
  },
  "cookie-policy": {
    label: "cookie policy",
    link: "https://www.sph.com.sg/legal/cookie-policy/",
  },
  "data-protection-policy": {
    label: "data protection policy",
    link: "https://www.sph.com.sg/legal/pdpa/",
  },
};

const logoLinks: Record<string, LogoItem> = {
  ab: {
    link: "/international/asean",
    src: logoAB,
    alt: "Asean Business",
  },
  ge: {
    link: "/international/global",
    src: logoGE,
    alt: "Global Eneterprise",
  },
  sgsme: {
    link: "/singapore/smes",
    src: logoSGSME,
    alt: "SGSME",
  },
  wealth: {
    link: "/wealth",
    src: logoWM,
    alt: "Wealth",
  },
  bt: {
    link: "/lifestyle",
    src: logoBTLifeStyle,
    alt: "BT Lifestyle",
  },
  btluxe: {
    link: "/lifestyle/bt-luxe",
    src: logoBTLuxe,
    alt: "BT Luxe",
  },
  btThrive: {
    link: "/thrive",
    src: logoBTThrive,
    alt: "BT Thrive",
    width: 120,
    height: 29,
  },
};

const footerLogos = [
  logoLinks.ab,
  logoLinks.ge,
  logoLinks.sgsme,
  logoLinks.wealth,
  logoLinks.bt,
  logoLinks.btluxe,
  logoLinks.btThrive,
];

const footerSocialLinksMobile = [
  socialLinks.fb,
  socialLinks.twitter,
  socialLinks.linkedin,
  socialLinks.telegram,
  socialLinks.rss,
  socialLinks.ig,
  { ...socialLinks.epaper, src: epaperAltIcon },
];

const footerSocialLinks: SocialLinkConfig[] = [
  { ...socialLinks.telegram, selfIcon: IconName.telegram },
  { ...socialLinks.whatsapp, selfIcon: IconName.whatsapp },
  { ...socialLinks.ig, selfIcon: IconName.instagram },
  { ...socialLinks.fb, selfIcon: IconName.facebook },
  { ...socialLinks.twitter, selfIcon: IconName.twitter },
  { ...socialLinks.linkedin, selfIcon: IconName.linkedin },
];

const footerFollowUsSocialLinks = [
  socialLinks.fb,
  socialLinks.ig,
  socialLinks.twitter,
  socialLinks.telegram,
  socialLinks.linkedin,
];

const footerNavigationItems = [
  navigationItems["about-us"],
  navigationItems["contact-us"],
  navigationItems.help,
  navigationItems["privacy-policy"],
  navigationItems["terms-conditions"],
  navigationItems["cookie-policy"],
  navigationItems["data-protection-policy"],
];

const footerSectionNavigationTitles = ["Sections", null, "Lifestyle"];

const footerSectionNavigationItems = [
  [
    sectionNavigationItems.singapore,
    sectionNavigationItems.international,
    sectionNavigationItems["opinion-features"],
    sectionNavigationItems["companies-markets"],
    sectionNavigationItems.property,
    sectionNavigationItems["startups-tech"],
    sectionNavigationItems.esg,
    sectionNavigationItems.wealth,
  ] as NavigationItem[],
  [
    sectionNavigationItems["working-life"],
    sectionNavigationItems["events-awards"],
    sectionNavigationItems["breaking-news"],
    sectionNavigationItems.epaper,
    sectionNavigationItems.podcasts,
    sectionNavigationItems.videos,
    sectionNavigationItems.newsletter,
  ] as NavigationItem[],
];

const footerLifestyleNavigationItems = [
  sectionNavigationItems["lifestyle"],
  sectionNavigationItems["lifestyle/food-drink"],
  sectionNavigationItems["lifestyle/motoring"],
  sectionNavigationItems["lifestyle/style-travel"],
  sectionNavigationItems["lifestyle/watches"],
  sectionNavigationItems["lifestyle/arts-design"],
  sectionNavigationItems["lifestyle/bt-luxe"],
];

const copyright: CopyrightItem = {
  title: "SPH MEDIA DIGITAL NEWS",
  content: `MDDI (P) 046/10/2024. Published by SPH Media Limited, Co. Regn. No. 202120748H. Copyright © ${dayjs().format(
    "YYYY"
  )} SPH Media Limited. All rights reserved.`,
};

const subscribeCTALink = {
  header: `${ESHOP_SUBSCRIBE_CTA_HEADER}`,
  footer: `${ESHOP_SUBSCRIBE_CTA_FOOTER}`,
};

const footerNewsNavigationItems = [
  sectionNavigationItems["breaking-news"],
  sectionNavigationItems["property"],
  sectionNavigationItems["property/residential"],
  sectionNavigationItems["property/commercial-industrial"],
  sectionNavigationItems["singapore"],
  sectionNavigationItems["international"],
  sectionNavigationItems["startups-tech"],
  sectionNavigationItems["opinion-features"],
  sectionNavigationItems["esg"],
  sectionNavigationItems["working-life"],
];

const footerCompaniesNavigationItems = [
  sectionNavigationItems["companies-markets"],
  sectionNavigationItems["companies-markets/banking-finance"],
  sectionNavigationItems["companies-markets/reits-property"],
  sectionNavigationItems["companies-markets/energy-commodities"],
  sectionNavigationItems["companies-markets/telcos-media-tech"],
  sectionNavigationItems["companies-markets/transport-logistics"],
  sectionNavigationItems["companies-markets/consumer-healthcare"],
  sectionNavigationItems["companies-markets/capital-markets-currencies"],
];

const footerWealthNavigationItems = [
  sectionNavigationItems["wealth"],
  sectionNavigationItems["wealth/wealth-investing"],
  sectionNavigationItems["wealth/personal-finance"],
  sectionNavigationItems["wealth/crypto-alternative-assets"],
  sectionNavigationItems["wealth/supplements"],
];

const footerMoreNavigationItems = [
  sectionNavigationItems.epaper,
  sectionNavigationItems.videos,
  sectionNavigationItems.newsletter,
  sectionNavigationItems.podcasts,
  {
    key: "Personal Subscription",
    link: "https://subscribe.sph.com.sg/promotions/bt-personal/",
    label: "Personal Subscription",
  },
  {
    key: "Group Subscription",
    link: "https://subscribe.sph.com.sg/corporate-plans/",
    label: "Group Subscription",
  },
  {
    ...sectionNavigationItems["paid-press-release"],
    label: "Paid Press Release",
  },
  { ...sectionNavigationItems["advertise"], label: "Advertise with Us" },
  sectionNavigationItems["events-awards"],
];

const footerRecommendsNavigationItems = [
  sectionNavigationItems["thrive"],
  sectionNavigationItems["startups-tech/tech-in-asia"],
  sectionNavigationItems["international/asean"],
  sectionNavigationItems["international/global"],
  sectionNavigationItems["singapore/smes"],
];

const footerSectionLabel = [
  {
    section: {
      key: "news",
      label: "News",
      children: footerNewsNavigationItems,
    },
  },
  {
    section: {
      key: "companies",
      label: "Companies",
      children: footerCompaniesNavigationItems,
    },
  },
  {
    section: {
      key: "wealth",
      label: "Wealth",
      children: footerWealthNavigationItems,
    },
  },
  {
    section: {
      key: "living",
      label: "Living",
      children: footerLifestyleNavigationItems,
    },
  },
  {
    section: {
      key: "more",
      label: "More",
      children: footerMoreNavigationItems,
    },
  },
];

const footerBtRecommends = [
  {
    section: {
      key: "recommends",
      label: "bt recommends",
      children: footerRecommendsNavigationItems,
    },
  },
];

export {
  appDownloadLinks,
  copyright,
  footerBtRecommends,
  footerFollowUsSocialLinks,
  footerLifestyleNavigationItems,
  footerLogos,
  footerNavigationItems,
  footerSectionLabel,
  footerSectionNavigationItems,
  footerSectionNavigationTitles,
  footerSocialLinks,
  footerSocialLinksMobile,
  logoLinks,
  navigationItems,
  socialLinks,
  subscribeCTALink,
};
