import { ReactElement } from "react";
import { Await } from "react-router-dom";
import { RouteFactory } from "@app/routePaths";
import { MBOContext } from "@app/types/Page";
import myBTLogoWhite from "@assets/bt-icon-white.svg";
import NoAdSettings from "@components/Advertisement/NoAdSettings";
import CustomError from "@components/Error/CustomError";
import GAData from "@components/GAData/GAData";
import MetaTags from "@components/MetaTags/MetaTags";
import MboSliderCarousel from "@components/Slider/MboSlider";
import { TRouteWithoutRedirect, useRouteContext } from "@sphtech/web2-core/ssr";
import { cn } from "@util/helpers";
import { HttpStatusCode } from "axios";

import MboReadMore from "./components/MboReadMore";

import styles from "./styles/Mbo.module.css";

export default function Mbo(): ReactElement {
  const { context } =
    useRouteContext<TRouteWithoutRedirect<MBOContext, string>>();

  if (context && context.statusCode !== HttpStatusCode.Ok) {
    // StatusCode is not 200
    return <CustomError statusCode={context.statusCode} />;
  }

  return (
    <Await resolve={context.payload} errorElement={<CustomError />}>
      {({ kind, data: { articles } }: MBOContext) => {
        return kind !== "static" ? (
          <CustomError statusCode={context.statusCode} />
        ) : (
          <div
            className="flex h-full w-full flex-grow flex-col justify-between antialiased"
            data-testid="mbo-component"
          >
            <MetaTags
              title="Metatags"
              description="The Business Times"
              ogType="article"
              slug={RouteFactory.mbo}
            />

            <NoAdSettings />

            <GAData title="BT_MBO" level2="miscellaneous" />

            <div
              className={cn(
                styles.mboLogoWrapper,
                "border-b-[9px] border-b-gray-350 bg-gray-850 font-times"
              )}
            >
              <img
                className={styles.mboLogo}
                alt="The Business Times"
                src={myBTLogoWhite}
                width={1024}
                height={104}
              />

              <MboReadMore
                className={cn(
                  styles.mboReadMoreTop,
                  "gap-8 font-public-sans text-11xl font-normal leading-5 tracking-tightest text-white"
                )}
              />
            </div>

            <div className="overflow-hidden">
              <MboSliderCarousel data={articles} />
            </div>

            <MboReadMore
              className={cn(
                styles.mboReadMoreBottom,
                "mt-0.5 w-full items-center justify-around bg-gray-250 px-4 font-public-sans tracking-tightest"
              )}
            />
          </div>
        );
      }}
    </Await>
  );
}
