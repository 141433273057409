import { Fragment } from "react";
import { useLocation } from "react-router-dom";
import type { SanitizedSectionArticle } from "@app/types/Cue";
import Ad from "@components/Advertisement/Ad";
import { useHandleMoreStories } from "@hooks/useHandleMoreStories";
import { compact } from "lodash-es";

import SectionListingStories from "./SectionListingStories";

export type OverviewProps = {
  testId?: string | null;
  data?: SanitizedSectionArticle[];
};

const pageSize = 10;

export default function Overview({
  testId = null,
  data = [],
}: OverviewProps): React.ReactElement {
  const matchedCategory = useLocation().pathname.replace("/", "");
  const [parentCategory = "", childCategory = ""] = matchedCategory.split("/");

  //compact removes all falsey values
  const uniqueCategoryQuery = compact([
    parentCategory !== "keywords" && parentCategory,
    childCategory,
  ]).join("_");

  const { ref, loadMoreData } = useHandleMoreStories({
    data,
    uniqueCategoryQuery,
    parentCategory,
    pageSize,
  });

  return (
    <section data-testid={testId}>
      <div className="stories">
        {[...data, ...loadMoreData].map(
          (article: SanitizedSectionArticle, index: number) => {
            return (
              <Fragment key={article.id}>
                {index === 5 ? (
                  <Ad
                    adType="bn1"
                    className="flex flex-col items-center justify-center border-b border-gray-175 py-3"
                  />
                ) : null}

                <SectionListingStories
                  key={index}
                  index={index}
                  article={article}
                />
              </Fragment>
            );
          }
        )}
      </div>

      <div ref={ref} />
    </section>
  );
}
