import { RefObject, useRef, useState } from "react";
import { RouteFactory } from "@app/routePaths";
import { NewsletterArchiveKeywordsEnums } from "@app/types/enums";
import { NewsletterArchiveContext } from "@app/types/Page";
import Ad from "@components/Advertisement/Ad";
import AdSettings from "@components/Advertisement/AdSettings";
import Container from "@components/Container/Container";
import GAData from "@components/GAData/GAData";
import Header from "@components/Header/Header";
import MetaTags from "@components/MetaTags/MetaTags";
import { ESG_INSIGHTS_CONTRIBUTORS } from "@components/Newsletter/constants";
import HomepageCarouselNewsletter from "@components/Newsletter/HomepageCarouselNewsletter";
import {
  BT_NEWSLETTERS,
  NEWSLETTER_TYPE,
} from "@components/Newsletter/types/Newsletter";
import NewsletterArchiveArticlesList from "@pages/Newsletter/Archive/components/NewsletterArchiveArticlesList";
import NewsletterArchiveHeader from "@pages/Newsletter/Archive/components/NewsletterArchiveHeader";
import SocialShare from "@pages/Newsletter/Archive/components/SocialShare";
import EsgInsightsLatestIssue from "@pages/Newsletter/Archive/EsgInsight/SampleIssues/EsgInsightsLatestIssue";
import { ENVIRONMENT } from "@util/constant";
import { cn, getEnvironmentDomain } from "@util/helpers";
import { useDetectAdBlock } from "adblock-detect-react";

export type EsgInsightsArchivePageContentProps = Pick<
  NewsletterArchiveContext,
  "data"
>;

export default function EsgInsightsArchivePageContent({
  data: { title, overview },
}: EsgInsightsArchivePageContentProps): React.ReactElement {
  const adBlockDetected = useDetectAdBlock();

  const divRef: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const [isKeepReadingVisible, setIsKeepReadingVisible] = useState(true);

  const esgNL = BT_NEWSLETTERS.filter(
    ({ type }) => type === NEWSLETTER_TYPE.ESG
  );
  const dynamicClasses = !isKeepReadingVisible ? "overflow-visible h-full" : "";

  const handleButtonClick = () => {
    setIsKeepReadingVisible(false);
  };

  const baseUrl = getEnvironmentDomain(ENVIRONMENT);

  return (
    <div
      className="w-full"
      data-testid="esg-insights-archive-page-content-component"
    >
      <AdSettings
        adNames={["lb1", "imu1", "catfish", "abm"]}
        path={RouteFactory.newsletterEsgInsightsArchivePage}
        adTargets={[{ key: "page", value: "listing" }]}
      />

      <MetaTags
        title={title}
        description="Read more at The Business Times."
        slug={RouteFactory.newsletterEsgInsightsArchivePage}
      />

      <GAData
        title="ESG Insights"
        level2="newsletter"
        adblocker={adBlockDetected ? 1 : 0}
      />

      <Ad
        adType="lb1"
        className="flex min-h-[calc(50px+0.50rem)] flex-col items-center justify-center border-b border-gray-175 bg-gray-125 py-1 text-center md:py-3 lg:min-h-[calc(250px+1.5rem)]"
      />

      <Header />

      <Container rootClassName="pt-5 !px-5">
        <div className="border-b-2 border-black">
          <NewsletterArchiveHeader
            newsletterTitle="ESG Insights"
            newsletterDescription="An exclusive weekly report on the latest environmental, social and governance issues."
            newsletterContributors={ESG_INSIGHTS_CONTRIBUTORS}
            isBigMoney={false}
            isSubscriber={false}
            newsletterRoute={RouteFactory.newsletterEsgInsightsArchivePage}
            newsletterTime={esgNL[0].updateTime}
            signUpNL={esgNL[0]}
            divRef={divRef}
            displayNewsletterForm={true}
          />
        </div>

        <NewsletterArchiveArticlesList
          newsletterKeyword={NewsletterArchiveKeywordsEnums.EsgInsight}
          articles={overview}
        />

        <div className="relative flex flex-col lg:hidden">
          <EsgInsightsLatestIssue
            divRef={divRef}
            firstChildClassName={dynamicClasses}
            ClassName={cn("h-[600px]", dynamicClasses)}
          />

          {isKeepReadingVisible ? (
            <div className="test absolute bottom-0 mb-[1px] ml-4 w-11/12 bg-nlArchiveGradient px-4 pb-4 pt-20">
              <button
                className="flex w-full flex-row
                items-start justify-center rounded-3xl border border-gray-850 py-3 lg:hidden"
                onClick={handleButtonClick}
              >
                Keep Reading
              </button>
            </div>
          ) : null}
        </div>

        <SocialShare
          shareUrl={baseUrl + RouteFactory.newsletterEsgInsightsArchivePage}
          rootClassName={"pt-12 block lg:hidden pb-20"}
        />

        <HomepageCarouselNewsletter
          newsletterToSkip={NEWSLETTER_TYPE.ESG}
          blockTitle="YOU MAY LIKE"
          rootClassName="border-t border-gray-250 md:border-black pt-3 mb-16"
        />
      </Container>

      <Ad adType="catfish" />

      <Ad adType="abm" />

      <Ad adType="prestitial" />
    </div>
  );
}
