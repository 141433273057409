import { Helmet } from "react-helmet-async";
import { Kind } from "@app/data";
import { KeyValuePair } from "@app/types/Page";
import useOKTAUserStore from "@src/app/store/useOKTAUserStore";
import { IS_PLAYWRIGHT } from "@util/constant";

import { SMX_PREBID_JS } from "./utils/constants";
import { getAdUnitData } from "./utils/helpers";
import { AdUnitNames } from "./utils/types";
import NoAdSettings from "./NoAdSettings";

type AdSettingsProps = {
  adNames: AdUnitNames[];
  path: string;
  adTargets?: KeyValuePair<string, string>[]; // page level ad targets
  slotTargets?: KeyValuePair<string, string>[]; // slot level ad targets
  pageType?: Kind;
};

export default function AdSettings({
  adNames,
  path,
  adTargets = [], // page level ad targets
  slotTargets = [], // slot level ad targets
  pageType,
}: AdSettingsProps): React.ReactElement {
  const OKTAUserInfo = useOKTAUserStore((state) => state.userInfo);

  console.log("[builder] adNames", adNames); // eslint-disable-line no-console
  console.log("[builder] IS_PLAYWRIGHT", IS_PLAYWRIGHT); // eslint-disable-line no-console

  // Disable ads in playwright.
  if (IS_PLAYWRIGHT) {
    return <NoAdSettings />;
  }

  // If initial gsChannels is empty string, do not render the ad settings.
  const gsChannels = slotTargets.find(
    (target) => target.key === "gs_channels"
  )?.value;

  if (gsChannels === "") return <></>;

  const adUnits = getAdUnitData(adNames, path, slotTargets);
  const pageData = {
    pageType: pageType,
    path: path,
    adUnits: adUnits,
    adTargets: adTargets,
    userInfo: OKTAUserInfo,
    smxPrebidJs: SMX_PREBID_JS,
  };
  const loaded_dfp_tags = adUnits.reduce((previous, current) => {
    return {
      ...previous,
      [current.type]: {
        adunit: current.pattern,
        slotname: current.id,
        size: Object.keys(current.slotSize)
          .map((key) => {
            const sizes = current.slotSize?.[parseInt(key)];

            return sizes?.toString().replaceAll(",", "x");
          })
          .join(","),
      },
    };
  }, {});

  return (
    <Helmet>
      <script
        type="text/javascript"
        src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"
        async
      />

      <script>{`${[
        "var googletag = googletag || {};",
        "googletag.cmd = googletag.cmd || [];",
        "googletag.slots = googletag.slots || {};",
        "window.bt_page_data = " + JSON.stringify(pageData) + ";",
        "var loaded_dfp_tags = '" + JSON.stringify(loaded_dfp_tags) + "';",
      ].join(" ")}(${overlaySEF.toString()})()`}</script>

      <script
        type="text/javascript"
        src="https://dsuwzj1tch87b.cloudfront.net/suid/suid.min.js"
      ></script>

      <script
        type="text/javascript"
        src="https://adtag.sphdigital.com/tag/smx/prebid.js"
      ></script>

      <script type="text/javascript" src={SMX_PREBID_JS}></script>

      <script
        type="text/javascript"
        src="/_web2/2.17.4/bt_ads.js?v=2.17.4"
        async
      ></script>

      <script
        type="text/javascript"
        src="/_web2/betterads_head2.js?v=2.17.4"
      ></script>
    </Helmet>
  );
}

// a self executing function injected into the head
const overlaySEF = function () {
  if (typeof window === "undefined") return;

  const hasDfpPreview = window.location.search.includes("dfp_preview");

  if (screen.width >= 768 && hasDfpPreview) {
    setTimeout(function () {
      const myPageOverlay = document.getElementById("MyPageOverlay");
      if (myPageOverlay) myPageOverlay?.classList.remove("overlayWhite");
    }, 4e3);
  }
};
