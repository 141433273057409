import { RouteFactory } from "@app/routePaths";
import { NewsletterArchiveKeywordsEnums } from "@app/types/enums";
import { NewsletterArchiveContext } from "@app/types/Page";
import NoAdSettings from "@components/Advertisement/NoAdSettings";
import GAData from "@components/GAData/GAData";
import { renderPageTitleV2 } from "@components/MetaTags/helpers";
import MetaTags from "@components/MetaTags/MetaTags";
import PulseEntry from "@pages/Pulse/components/PulseEntry/PulseEntry";
import ThriveFavicon from "@pages/Thrive/components/ThriveFavicon";
import ThriveFooter from "@pages/Thrive/components/ThriveFooter";
import ThriveGetSocial from "@pages/Thrive/components/ThriveGetSocial";
import ThriveHeader from "@pages/Thrive/components/ThriveHeader";
import ThriveMiniAboutUs from "@pages/Thrive/components/ThriveMiniAboutUs";
import { thrivePastIssuesPageMetaTags } from "@pages/Thrive/constants";

import ThrivePastIssueArticles from "./ThrivePastIssueArticles";
import ThriveWantMore from "./ThriveWantMore";

export type ThriveArchiveContentProps = Pick<NewsletterArchiveContext, "data">;

export default function ThrivePastIssuesContent({
  data: { overview },
}: ThriveArchiveContentProps): React.ReactElement {
  const MetaTagsProps = thrivePastIssuesPageMetaTags;
  const title = renderPageTitleV2({
    kind: "custom",
    title:
      "Past Newsletters, thrive - The Business Times | Career, Money and Life for Gen Zs in Singapore",
  });
  return (
    <div
      className="w-full overflow-hidden bg-verticals-thrive"
      data-testid="thrive-past-issues-content-component"
    >
      <ThriveFavicon />

      <NoAdSettings />

      <MetaTags
        title={title}
        ogSiteName={title}
        description={MetaTagsProps.description}
        ogDescription={MetaTagsProps.description}
        ogImageAlt={MetaTagsProps.description}
        ogImageUrl={MetaTagsProps.ogImageUrl}
        slug={RouteFactory.thrivePastIssues}
      />

      <GAData title="Thrive_Past_Issues" level2="thrive" />

      <ThriveHeader />

      <div className="mx-6 flex justify-center md:mx-0">
        <ThrivePastIssueArticles
          newsletterKeyword={NewsletterArchiveKeywordsEnums.Thrive}
          articles={overview}
        />
      </div>

      <ThriveWantMore
        subscriptionTitle="Want more?"
        subscriptionDescription="Get the good stuff on work and money, every Friday."
      />

      <ThriveGetSocial />

      <ThriveMiniAboutUs />

      <div className="flex justify-center bg-gray-850 font-poppins">
        <ThriveFooter />
      </div>

      <PulseEntry />
    </div>
  );
}
