import { FormEvent, HTMLAttributes, useState } from "react";
import iconCheck from "@assets/icon-check-white.svg";
import { EmarsysResponse } from "@components/Newsletter/types/Emarsys";
import {
  BT_NEWSLETTERS,
  NEWSLETTER_TYPE,
} from "@components/Newsletter/types/Newsletter";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Input, Transition } from "@headlessui/react";
import { newsletterRequests } from "@pages/Newsletter/SignUp/Newsletter.server";
import { cn } from "@util/helpers";

export default function ThriveNewsletter({
  className,
}: Pick<HTMLAttributes<HTMLDivElement>, "className">): React.ReactElement {
  const [isLoading, setIsLoading] = useState(false);
  const [emarsysResponse, setEmarsysResponse] = useState<EmarsysResponse>();

  const sendDataToGTM = useGTMDispatch();

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setIsLoading(true);

    const target = event.target as typeof event.target & {
      email: { value: string };
    };

    const request = async () => {
      const newsletter = BT_NEWSLETTERS.filter(
        ({ type }) => type === NEWSLETTER_TYPE.THRIVE
      );

      await newsletterRequests
        .processSubscription(target.email.value, newsletter)
        .then((response) => {
          setEmarsysResponse(response);

          if (response.status === "success") {
            sendDataToGTM({
              event: "custom_event",
              eventCategory: "newsletter",
              eventAction: "signup",
              eventLabel: "successful sign up",
            });
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    request();
  };

  return (
    <div data-testid="thrive-newsletter" className={className}>
      <div className="my-3 grid grid-cols-1 items-center gap-3 border-t border-gray-175 pt-4 md:px-4">
        <p className="col-span-1 mb-0 font-public-sans text-base leading-6">
          <strong>Straight to your inbox.</strong> Money, career and life hacks
          to help young adults stay ahead.
        </p>

        <Transition show={emarsysResponse?.status === "success"}>
          <div className="ease transition duration-300 data-[closed]:translate-y-9 data-[closed]:opacity-0">
            <div
              role="alert"
              className="text-gray-85 tracking-2 relative col-span-1 flex items-center gap-2 bg-white font-public-sans text-base font-normal"
            >
              <span className="flex aspect-square h-6 w-6 items-center justify-center rounded-full bg-orange-400 p-1">
                <img
                  src={iconCheck}
                  width={24}
                  height={24}
                  className="inline-block w-full"
                />
              </span>
              Welcome to the thrive. community! 🙆
            </div>
          </div>
        </Transition>

        {emarsysResponse?.status !== "success" ? (
          <form
            onSubmit={handleSubmit}
            className="col-span-1 flex flex-row flex-wrap items-start gap-3"
          >
            <div className="w-full lg:w-[18.75rem]">
              <Input
                placeholder="Your email address"
                className={cn(
                  "order-1 w-full max-w-full border border-gray-850 px-5 py-2 font-poppins text-xs font-normal leading-5 text-gray-850 outline-none placeholder:text-gray-950 focus:border-gray-850",
                  "placeholder-shown:border-gray-950",
                  { "!border-red": emarsysResponse?.status === "error" }
                )}
                type="email"
                name="email"
                required
              />

              {emarsysResponse?.status === "error" &&
              emarsysResponse?.message ? (
                <div className="order-2 col-span-1 mt-1 w-full md:col-span-3 lg:col-span-1 xl:col-span-3">
                  <p className="font-poppins text-xs font-medium text-red">
                    {emarsysResponse.message}
                  </p>
                </div>
              ) : null}
            </div>

            <Button
              type="submit"
              className="order-3 w-[5.875rem] max-w-full rounded-full bg-orange-400 px-5 py-2 font-poppins text-xs font-semibold leading-5 text-white"
              onClick={() => {
                sendDataToGTM({
                  event: "custom_event",
                  eventCategory: "newsletter",
                  eventAction: "click",
                  eventLabel: "sign up",
                });
              }}
            >
              {isLoading ? (
                <FontAwesomeIcon
                  icon={faSpinner}
                  size="lg"
                  color="text-white"
                  spin
                />
              ) : (
                "Sign up"
              )}
            </Button>
          </form>
        ) : null}
      </div>
    </div>
  );
}
