import { Link } from "react-router-dom";
import { RouteFactory } from "@app/routePaths";
import { WealthContext } from "@app/types/Page";
import Ad from "@components/Advertisement/Ad";
import AdSettings from "@components/Advertisement/AdSettings";
import Breadcrumb from "@components/Breadcrumb/Breadcrumb";
import Container from "@components/Container/Container";
import GAData from "@components/GAData/GAData";
import Column from "@components/Grid/Column";
import Row from "@components/Grid/Row";
import Header from "@components/Header/Header";
import { useBreadCrumbState } from "@components/Header/helpers";
import MetaTags from "@components/MetaTags/MetaTags";
import { useTrimmedBreakingNews } from "@hooks/useTrimmedBreakingNews";
import PulseEntry from "@pages/Pulse/components/PulseEntry/PulseEntry";
import BreakingNews from "@pages/Section/components/BreakingNews/BreakingNews";
import { sectionNavigationItems } from "@util/constant";
import { cn } from "@util/helpers";
import { useDetectAdBlock } from "adblock-detect-react";

import TodaysMarketData from "./components/TodaysMarketData";
import WealthRealEstate from "./components/WealthRealEstate";
import WealthThirdRow from "./components/WealthThirdRow";
import WealthTopStories from "./components/WealthTopStories";
import WealthWatches from "./components/WealthWatches";

export type WealthPageContentProps = Pick<WealthContext, "data">;

export default function WealthPageContent({
  data: {
    title,
    wealth,
    property,
    invest,
    advisor,
    asset,
    watches,
    wealthAttachment,
  },
}: WealthPageContentProps): React.ReactElement {
  const { breadcrumbsRef, isShowBreadCrumb } = useBreadCrumbState();
  const adBlockDetected = useDetectAdBlock();
  const breakingNews = useTrimmedBreakingNews();
  return (
    <div className="w-full" data-testid="wealth-page-content-component">
      <AdSettings
        adNames={[
          "lb1",
          "imu1",
          "imu2",
          "imu3",
          "prestitial",
          "catfish",
          "abm",
        ]}
        path={RouteFactory.wealth}
        adTargets={[{ key: "page", value: "listing" }]}
      />

      <MetaTags
        title={title}
        description="Read more at The Business Times."
        slug={RouteFactory.wealth}
      />

      <GAData
        title={"Wealth_Index"}
        level2={"wealth"}
        adblocker={adBlockDetected ? 1 : 0}
      />

      <Ad
        adType="lb1"
        className="flex min-h-[calc(50px+0.50rem)] flex-col items-center justify-center border-b border-gray-175 bg-gray-125 py-1 text-center md:py-3 lg:min-h-[calc(250px+1.5rem)]"
      />

      <Header
        displayBreadcrumb={sectionNavigationItems["wealth"] && isShowBreadCrumb}
        parentCategory={"wealth"}
        categoryDataMapping={sectionNavigationItems}
      />

      <PulseEntry />

      {sectionNavigationItems["wealth"] ? (
        <Container>
          <div>
            <h1 className="pb-3 pt-6 font-playfair text-8xl font-semibold uppercase text-gray-850">
              {"Wealth"}
            </h1>

            <div ref={breadcrumbsRef}>
              <Breadcrumb
                className="m-auto"
                parentCategory={"wealth"}
                categoryDataMapping={sectionNavigationItems}
                extraBreadcrumbItems={
                  <div
                    className={cn(
                      "relative box-border flex shrink-0 items-center border-r border-gray-175 pr-3 font-poppins text-sm font-bold tracking-tightest text-gray-850 hover:opacity-80"
                    )}
                  >
                    <Link
                      to={`${RouteFactory.wealth}?ref=listing-menubar`}
                      reloadDocument
                    >
                      Top stories
                    </Link>
                  </div>
                }
              />
            </div>
          </div>
        </Container>
      ) : null}

      <Container rootClassName="pt-6">
        <Row rootClassName="mb-6">
          <Column rootClassName="w-full px-3 lg:w-8/12">
            <WealthTopStories data={wealth} />
          </Column>

          <Column rootClassName="w-full px-3 lg:w-4/12">
            <Ad
              adType="imu1"
              className="mb-6 flex flex-col items-center justify-center"
            />

            {wealthAttachment ? (
              <TodaysMarketData wealthAttachment={wealthAttachment} />
            ) : null}

            <BreakingNews data={breakingNews} rootClassName="mx-auto mb-6" />
          </Column>
        </Row>

        <>
          {property ? (
            <Row rootClassName="mb-6">
              <Column rootClassName="w-full">
                <WealthRealEstate data={property} />
              </Column>
            </Row>
          ) : null}
        </>

        <>
          {invest && advisor && asset ? (
            <Row rootClassName="mb-6">
              <Column rootClassName="w-full">
                <WealthThirdRow
                  invest={invest}
                  advisor={advisor}
                  asset={asset}
                />
              </Column>
            </Row>
          ) : null}
        </>

        <>
          {watches ? (
            <Row rootClassName="mb-6">
              <Column rootClassName="w-full">
                <WealthWatches articles={watches} />
              </Column>
            </Row>
          ) : null}
        </>
      </Container>

      <Ad adType="catfish" />

      <Ad adType="abm" />

      <Ad adType="prestitial" />
    </div>
  );
}
