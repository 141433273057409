import { useEffect } from "react";
import giftIcon from "@assets/icon-gift.svg";
import Button from "@components/Button/Button";
import { ActionType, ButtonSize, IconName } from "@components/Button/types";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { LambdaResponsePayload } from "@pages/Article/types/Alacrity";
import { getIconSrc } from "@src/app/components/Button/helpers";
import { OKTAUserTypeEnum } from "@store/useOKTAUserStore";
import { cn } from "@util/helpers";

export type ArticleSubshareProps = {
  isPremium: boolean;
  userType?: OKTAUserTypeEnum;
  handleGenerateGiftURLModal: () => void;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  alacrityRes?: LambdaResponsePayload;
  displayNewGiftIcon?: boolean;
};

export default function ArticleSubshare({
  isPremium = false,
  userType = OKTAUserTypeEnum.ANONYMOUS,
  handleGenerateGiftURLModal,
  setIsModalOpen,
  alacrityRes,
  displayNewGiftIcon = false,
}: ArticleSubshareProps): React.ReactElement {
  const sendDataToGTM = useGTMDispatch();

  const handleClick = () => {
    sendDataToGTM({
      event: "custom_event",
      eventCategory: "bt subshare",
      eventAction: "click",
      eventLabel: "gift icon clicks for subscribers",
      nonInteraction: false,
    });

    if (alacrityRes) {
      setIsModalOpen(true);
    } else {
      handleGenerateGiftURLModal();
    }
  };

  useEffect(() => {
    if (!isPremium) return;
    if (userType !== OKTAUserTypeEnum.SUBSCRIBER) return;

    sendDataToGTM({
      event: "custom_event",
      eventCategory: "bt subshare",
      eventAction: "views",
      eventLabel: "gift icon exposure for subscribers",
      nonInteraction: false,
    });
  }, [isPremium, userType, sendDataToGTM]);

  return (
    <>
      {isPremium && userType === OKTAUserTypeEnum.SUBSCRIBER ? (
        <div data-testid="article-subshare-component">
          {displayNewGiftIcon ? (
            <Button
              onClick={handleClick}
              actionType={ActionType.actionButton}
              icon={IconName.gift}
              buttonSize={ButtonSize.medium}
              iconSize={42}
              testid="action-button-subshare"
            />
          ) : (
            <button
              onClick={handleClick}
              className={cn("h-10 w-10 outline-none hover:opacity-70", {
                "flex h-[42px] w-[42px] rounded-full border border-gray-175 hover:border-gray-850 hover:opacity-100":
                  displayNewGiftIcon,
              })}
              aria-label="Gift this subscriber-only story to your friends and family"
            >
              <img
                className="h-full w-full"
                src={displayNewGiftIcon ? getIconSrc(IconName.gift) : giftIcon}
                width={42}
                height={42}
                alt="Share this article."
              />
            </button>
          )}
        </div>
      ) : null}
    </>
  );
}
