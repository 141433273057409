export enum SocialMediaType {
  facebook = "facebook",
  telegram = "telegram",
  email = "email",
  linkedin = "linkedin",
  twitter = "twitter",
  whatsapp = "whatsapp",
  instagram = "instagram",
}

export enum IconName {
  facebook = "facebook",
  telegram = "telegram",
  email = "email",
  linkedin = "linkedin",
  twitter = "twitter",
  whatsapp = "whatsapp",
  instagram = "instagram",
  profile = "profile",
  search = "search",
  hamburgerForDesktop = "hamburgerForDesktop",
  hamburgerForMobile = "hamburgerForMobile",
  gift = "gift",
  closeWhite = "closeWhite",
  share = "share",
  link = "link",
}

export type ButtonProps = {
  className?: string;
  whichSocialMedia?: SocialMediaType;
  shareUrl?: string;
  icon?: IconName;
  text?: string;
  actionType?: ActionType;
  imgAlt?: string;
  title?: string;
  buttonSize?: ButtonSize;
  iconSize?: number;
  onClick?: (e: React.SyntheticEvent) => void;
  testid?: string;
};

export enum ButtonSize {
  small = "sm",
  medium = "md",
  large = "lg",
}

export enum ActionType {
  linkButton = "linkButton",
  shareButton = "shareButton",
  actionButton = "actionButton",
}
