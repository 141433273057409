import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { RouteFactory } from "@app/routePaths";
import BTLogo from "@assets/bt-icon-white.svg";
import pulseOgImage from "@assets/pulse-og-image.jpg";
import NoAdSettings from "@components/Advertisement/NoAdSettings";
import { getIconSrc } from "@components/Button/helpers";
import { IconName } from "@components/Button/types";
import GAData from "@components/GAData/GAData";
import { URL_ORIGIN } from "@components/MetaTags/constants";
import MetaTags from "@components/MetaTags/MetaTags";
import { Transition, TransitionChild } from "@headlessui/react";
import { useWindowSize } from "@hooks/useWindowSize";
import useSendPulseGaData from "@pages/Pulse/hooks/useSendPulseGaData";
import {
  filteredPulseArticles,
  getPulseGaTitle,
} from "@pages/Pulse/utils/helpers";
import { dayjsSingaporeTimezone } from "@util/constant";
import { toUnderscoreFromSpace } from "@util/helpers";
import dayjs from "dayjs";
import Cookies from "js-cookie";

import PulseStartingScreen from "./components/PulseStartingScreen";
import type { PulsePageContent } from "./utils/types";
import { SanitizedPulseData } from "./utils/types";
import PulseDesktopPage from "./PulseDesktopPage";
import PulseMobilePage from "./PulseMobilePage";

export default function PulsePageContent({
  data: { title, pulseArticles, pulseIndividualArticles },
}: PulsePageContent): React.ReactElement {
  const navigate = useNavigate();

  const [sortedPulseData, setSortedPulseData] = useState<SanitizedPulseData[]>(
    []
  );
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const [currentSlideId, setCurrentSlideId] = useState<string>("");

  const [displayStartScreen, setDisplayStartScreen] = useState(false);
  const [startCarousel, setStartCarousel] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);

  const [backToBt, setBackToBt] = useState("");

  const { sendTrackingData, sendCustomEvent } = useSendPulseGaData();
  const { isScreenLG } = useWindowSize();

  const handlePageChange = useCallback(
    (
      data: SanitizedPulseData[],
      activeId: string,
      setCookie: boolean = false,
      route: string = RouteFactory.pulseSlide(activeId),
      setFirstScreen: boolean = false,
      title: string = "",
      sendGTMData: boolean = true
    ) => {
      setFirstLoad(false);
      setSortedPulseData(data);
      setCurrentSlideId(activeId);
      setStartCarousel(!setFirstScreen);
      setDisplayStartScreen(setFirstScreen);

      if (setCookie) {
        Cookies.set("BTPulse", "shown");
      }

      navigate(route);

      if (title === "" && sendGTMData) sendTrackingData("", "", activeId, data);
      if (title !== "" && sendGTMData)
        sendTrackingData("pulse intro", "pulse/intro");
    },
    [navigate, sendTrackingData]
  );

  const handleStartCarousel = () => {
    if (pulseArticles.length === 0) return;
    const firstArticle = pulseArticles?.[0];

    const route = RouteFactory.pulseSlide(firstArticle.id);

    sendCustomEvent("intro", "start");

    handlePageChange(
      pulseArticles,
      firstArticle.id,
      true,
      route,
      false,
      "",
      false
    );

    sendTrackingData("", "", firstArticle.id, pulseArticles);
  };

  useEffect(() => {
    if (pulseArticles.length === 0) return;
    if (!firstLoad) return;

    const path = sessionStorage.getItem("pulse_back_path") || "/";
    setBackToBt(path);

    const cookie = Cookies.get("BTPulse");

    const firstArticle = pulseIndividualArticles?.[0];
    const activeId = firstArticle?.id || "";

    const withArticleId = pulseArticles.find(
      (article) => article.id === activeId
    );

    const data = withArticleId
      ? filteredPulseArticles(activeId, pulseArticles)
      : activeId !== "" && !withArticleId
        ? [...(pulseIndividualArticles || []), ...pulseArticles]
        : pulseArticles;

    const setCookie = !(activeId === "" && typeof cookie === "undefined");

    const firstArticleInFullList = pulseArticles?.[0];

    const route =
      withArticleId || (activeId !== "" && !withArticleId)
        ? RouteFactory.pulseSlide(activeId)
        : typeof cookie === "undefined"
          ? RouteFactory.pulse
          : RouteFactory.pulseSlide(firstArticleInFullList.id);

    const setFirstScreen = !(
      withArticleId ||
      (activeId !== "" && !withArticleId) ||
      typeof cookie !== "undefined"
    );

    const sendGTMTitle =
      activeId === "" && typeof cookie === "undefined" ? "pulse intro" : "";

    const displayArticleId =
      activeId === "" ? firstArticleInFullList.id : activeId;

    handlePageChange(
      data,
      displayArticleId,
      setCookie,
      route,
      setFirstScreen,
      sendGTMTitle
    );
  }, [firstLoad, pulseArticles, pulseIndividualArticles, handlePageChange]);

  const firstArticle = sortedPulseData.filter(
    (article) => article.id === currentSlideId
  )?.[0];

  const metaTitle = firstArticle?.title || title;
  const metaDesc =
    firstArticle?.standfirst ||
    firstArticle?.summaries?.[0] ||
    "Your 5 minutes daily news summary.";
  const metaSlug = firstArticle?.slug || RouteFactory.pulse;
  const metaImage = firstArticle
    ? `${firstArticle?.media}?w=1200&h=630&f=jpg`
    : `${URL_ORIGIN}${pulseOgImage}`;

  const gaTitle = getPulseGaTitle(
    displayStartScreen,
    currentSlide,
    sortedPulseData,
    metaTitle
  );

  return (
    <div
      className="flex min-h-svh w-full flex-col bg-[#121212]"
      data-testid="pulse-page-content"
    >
      <NoAdSettings />

      <GAData
        event="virtual_pv"
        level2="pulse"
        contentcat={1}
        contenttype={
          displayStartScreen || currentSlide === sortedPulseData.length
            ? ""
            : "1"
        }
        title={toUnderscoreFromSpace(gaTitle)}
        articleid={firstArticle?.id || ""}
        pubdate={
          displayStartScreen || currentSlide === sortedPulseData.length
            ? "(not set)"
            : dayjs(firstArticle?.published)
                .tz(dayjsSingaporeTimezone)
                .format("YYYY-MM-DD HH:mm:ss")
        }
        author={firstArticle?.authors || "(not set)"}
        gsChannels="(not set)"
      />

      <MetaTags
        title={metaTitle}
        description={metaDesc}
        ogDescription={metaDesc}
        ogType="website"
        slug={metaSlug}
        imageUrl={metaImage}
        imageAlt="Pulse"
      />

      <div className="relative z-20 hidden justify-between p-4 pb-0 lg:flex">
        <Link to={`${RouteFactory.home}?ref=pulse`} reloadDocument>
          <img src={BTLogo} width={200} height={18} alt="The Business Times" />
        </Link>

        <Link to={`${backToBt}?ref=pulse`} reloadDocument>
          <img
            src={getIconSrc(IconName.closeWhite)}
            width={36}
            height={36}
            alt="close"
          />
        </Link>
      </div>

      <Transition show={displayStartScreen && !startCarousel}>
        <TransitionChild
          as={"div"}
          enter="transition ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition ease-in duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 left-0 overflow-y-auto overflow-x-hidden lg:overflow-hidden">
            <PulseStartingScreen
              handleStartCarousel={handleStartCarousel}
              backToBt={backToBt}
            />
          </div>
        </TransitionChild>
      </Transition>

      <Transition
        show={
          !displayStartScreen && startCarousel && sortedPulseData.length > 0
        }
      >
        <TransitionChild
          as={"div"}
          enter="transition ease duration-300"
          enterFrom="opacity-0 translate-x-80"
          enterTo="opacity-100 translate-x-0"
          leave="transition ease duration-500"
          leaveFrom="opacity-100 translate-x-0"
          leaveTo="opacity-0 translate-x-80"
        >
          <div className="fixed inset-0 left-0">
            {isScreenLG ? (
              <PulseDesktopPage
                sortedPulseData={sortedPulseData}
                currentSlide={currentSlide}
                setCurrentSlide={setCurrentSlide}
                setCurrentSlideId={setCurrentSlideId}
                backToBt={backToBt}
              />
            ) : (
              <PulseMobilePage
                sortedPulseData={sortedPulseData}
                currentSlide={currentSlide}
                setCurrentSlide={setCurrentSlide}
                setCurrentSlideId={setCurrentSlideId}
                currentSlideId={currentSlideId}
                backToBt={backToBt}
              />
            )}
          </div>
        </TransitionChild>
      </Transition>

      <div className="absolute bottom-6 right-6 hidden font-poppins text-4xs font-light tracking-2% text-gray-550 lg:block">
        AI-generated summary.{" "}
        <a
          target="_blank"
          href="https://subscribe.sph.com.sg/contact-us/"
          className="underline-offset-4 hover:underline"
          rel="noreferrer"
        >
          Feedback.
        </a>
      </div>
    </div>
  );
}
