export const URL_ORIGIN = "https://www.businesstimes.com.sg";
export const URL_ORIGIN_ALTERNATE =
  "android-app://com.ps.bt/https/www.businesstimes.com.sg";
export const URL_ORIGIN_TWITTER =
  "com.sph.bt://url=https://www.businesstimes.com.sg";

export const GOOGLE_SITE_VERIFICATION =
  "64YzaSIKUvOcgG1DccSNNzlWDqXwjSx4lDDgbgtDLJE";
export const FORMAT_DETECTION = "telephone=no";

export const OG_SITE_NAME = "The Business Times";
export const FB_ADMINS = "100008016031098";
export const FB_PAGES = "288031731262011";
export const FB_APP_ID = "328386607332790";

export const TWITTER_CARD = "summary_large_image";
export const TWITTER_SITE = "@BusinessTimes";
export const TWITTER_APP_COUNTRY = "US";
export const TWITTER_APP_ID_IPHONE = "531275824";
export const TWITTER_APP_ID_IPAD = "531283825";
export const TWITTER_APP_ID_GOOGLEPLAY = "com.ps.bt";

export const IOS_APP_STORE_ID = "531275824";
export const IOS_APP_NAME = "Business Times";
export const ANDRIOD_PACKAGE = "com.ps.bt";
export const ANDRIOD_APP_NAME = "The Business Times";
export const READ_MORE_AT_BT = "Read more at The Business Times.";
export const OG_IMAGE_HEIGHT = "630";
export const OG_IMAGE_WIDTH = "1200";

// Since the meta title cannot be edited in CUE, this object is used to override the title for specific sections.
export const customSectionTitles: Record<string, string> = {
  Singapore: "Singapore Business & Financial News - The Business Times",
  "Companies & Markets":
    "Companies & Markets News - Singapore - The Business Times",
  Property: "Property News - Singapore - The Business Times",
  Lifestyle: "Lifestyle News - Singapore - The Business Times",
};

// Since the meta descriptons cannot be edited in CUE, this object is used to override the description for specific sections.
export const customSectionDescriptions: Record<string, string> = {
  Singapore:
    "Latest economic policies, initiatives for SMEs, and developments shaping Singapore's business landscape, only at The Business Times.",
  "Companies & Markets":
    "Top news on Companies & Markets from Singapore, Asia, and around the globe. Stay updated with the latest stocks, share prices, regulatory movements and broader economic impact, only at The Business Times.",
  Property:
    "HDB developments, BTO applications, and all property news. Explore market trends, and expert analyses about Singapore's real estate landscape, only at The Business Times.",
  Lifestyle:
    "Latest Lifestyle news and reviews: dining, fashion, wellness, watches and travel, plus expert insights and trends that define Singapore's vibrant culture, exclusively from The Business Times.",
};
